import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useMetadataStore } from '../../stores/MetadataStore'
import styles from './style.less'
import {
  calculateAcceleration,
  Metadata,
  Timestamp,
  TurnSignal,
} from '../../utils/protobufParse'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import { useVideosStore } from '../../stores/VideosStore'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import MetadataRow from './MetadataRow'

interface MetadataLogProps {}

const findData = (data: any[], timestamp: Timestamp) =>
  data?.filter((d) => d.time_stamp.seconds === timestamp.seconds)[0]

const convertPositionCovariance = (positionCovariance: number[]) =>
  positionCovariance.map((pc) => pc / 100)

const filterData = (data: any, start: number, end: number) =>
  data.filter((d: any) => {
    return d.time_stamp?.seconds >= start && d.time_stamp?.seconds <= end
  })

const filterSafetyScoreData = (data: any, start: number, end: number) =>
  data.filter(
    (d: any) =>
      d.clip.start_timestamp?.seconds >= start &&
      d.clip.end_timestamp.seconds <= end
  )

const MetadataLog: React.FunctionComponent<MetadataLogProps> = () => {
  const [metadata, setMetadata] = useState<Metadata>({
    driveSessionInfo: [],
    gnss: [],
    vehicleMotion: [],
    vehicleState: [],
    safetyScore: [],
    way: [],
    curriculumPoint: [],
    curriculumLineString: [],
  })
  const {
    url,
    seconds,
    updateMetadata,
    updateSeconds,
    settings,
    updateMetadataLoadingFinished,
    loadingFinished,
  } = useMetadataStore(
    useShallow((state) => ({
      url: state.url,
      seconds: state.seconds,
      updateMetadata: state.updateMetadata,
      updateSeconds: state.updateSeconds,
      settings: state.metadataSettings,
      updateMetadataLoadingFinished: state.updateMetadataLoadingFinished,
      loadingFinished: state.loadingFinished,
    }))
  )
  const { begin, offset, context, end } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      end: state.end,
      offset: state.offset,
      context: state.context,
    }))
  )
  const { session } = useVideosStore(
    useShallow((state) => ({
      session: state.session,
    }))
  )
  const workerRef = useRef<Worker>()

  useEffect(() => {
    if (url && !workerRef.current) {
      const newWorker: Worker = new Worker(
        new URL('./../../utils/worker', import.meta.url)
      )
      newWorker.postMessage({
        url,
        begin:
          begin !== undefined &&
          new Date(session.startTimestamp).getTime() / 1000 + begin - context,
        end:
          end !== undefined &&
          new Date(session.endTimestamp).getTime() / 1000 + end + context,
      })
      workerRef.current = newWorker

      newWorker.onmessage = function (event) {
        if (event.data.log) {
          setMetadata((prevMetadata) => {
            const driveSessionInfo =
              prevMetadata.driveSessionInfo.length === 0
                ? event.data.log.driveSessionInfo
                : prevMetadata.driveSessionInfo

            if (
              begin &&
              session.offsetURLStartTimestamp &&
              session.offsetURLEndTimestamp
            ) {
              const start = Math.floor(
                new Date(session.offsetURLStartTimestamp).getTime() / 1000
              )
              const end = Math.floor(
                new Date(session.offsetURLEndTimestamp).getTime() / 1000
              )

              return {
                driveSessionInfo,
                vehicleMotion: prevMetadata.vehicleMotion.concat(
                  filterData(event.data.log.vehicleMotion, start, end)
                ),
                gnss: prevMetadata.gnss.concat(
                  filterData(event.data.log.gnss, start, end)
                ),
                vehicleState: prevMetadata.vehicleState.concat(
                  filterData(event.data.log.vehicleState, start, end)
                ),
                safetyScore: prevMetadata.safetyScore.concat(
                  filterSafetyScoreData(event.data.log.safetyScore, start, end)
                ),
                way: prevMetadata.way.concat(event.data.log.way),
                start,
                end,
                curriculumPoint: prevMetadata.curriculumPoint.concat(
                  filterData(event.data.log.curriculumPoint, start, end)
                ),
                curriculumLineString: prevMetadata.curriculumLineString.concat(
                  filterData(event.data.log.curriculumLineString, start, end)
                ),
              } as Metadata
            } else {
              return {
                driveSessionInfo,
                vehicleMotion: prevMetadata.vehicleMotion.concat(
                  event.data.log.vehicleMotion
                ),
                gnss: prevMetadata.gnss.concat(event.data.log.gnss),
                vehicleState: prevMetadata.vehicleState.concat(
                  event.data.log.vehicleState
                ),
                safetyScore: prevMetadata.safetyScore.concat(
                  event.data.log.safetyScore
                ),
                way: prevMetadata.way.concat(event.data.log.way),
                curriculumPoint: prevMetadata.curriculumPoint.concat(
                  event.data.log.curriculumPoint
                ),
                curriculumLineString: prevMetadata.curriculumLineString.concat(
                  event.data.log.curriculumLineString
                ),
              } as Metadata
            }
          })
        }

        if (event.data.finished) {
          updateMetadataLoadingFinished(true)
        }
      }
    }

    return () => {
      const worker = workerRef.current
      if (worker) {
        worker.terminate()
      }
      workerRef.current = undefined
    }
  }, [url, workerRef, begin, session, end, context])

  const vehicleState = useMemo(() => {
    if (metadata && seconds && seconds.length > 0 && loadingFinished) {
      const time =
        metadata.vehicleState?.[0]?.time_stamp.seconds +
        offset -
        begin +
        context
      return metadata.vehicleState.find(
        (vS) => vS.time_stamp.seconds === (time | 0)
      )
    }
  }, [offset, seconds, metadata, loadingFinished, begin, context])
  const timeStamp = useMemo(() => vehicleState?.time_stamp, [vehicleState])

  const vehicleMotion = useMemo(
    () => (timeStamp ? findData(metadata.vehicleMotion, timeStamp) : null),
    [metadata, timeStamp]
  )
  const gnss = useMemo(
    () => (timeStamp ? findData(metadata.gnss, timeStamp) : null),
    [metadata, timeStamp]
  )

  useEffect(() => {
    if (metadata) {
      updateMetadata(metadata)
      const seconds = metadata.gnss?.map((g) => g.time_stamp.seconds)
      updateSeconds(seconds)
    }
  }, [metadata])

  return (
    <div className={styles.metadataLog}>
      {metadata && (
        <table>
          <thead>
            <tr>
              <th className={styles.valCol}>
                <Typography
                  type={TypographyTypes.label}
                  color="color-neutral-040"
                  version={Version.v2}
                >
                  Property
                </Typography>
              </th>
              <th className={styles.valCol}>
                <Typography
                  type={TypographyTypes.label}
                  color="color-neutral-040"
                  version={Version.v2}
                >
                  Value
                </Typography>
              </th>
              <th className={styles.unitCol}>
                <Typography
                  type={TypographyTypes.label}
                  color="color-neutral-040"
                  version={Version.v2}
                >
                  Units
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {vehicleMotion && (
              <>
                {timeStamp && (
                  <MetadataRow
                    property="timestamp"
                    value={new Date(timeStamp.seconds).getTime()}
                  />
                )}
                {settings.acceleration.display && (
                  <MetadataRow
                    property="acceleration"
                    value={parseFloat(
                      calculateAcceleration(vehicleMotion).toFixed(3)
                    )}
                    unit="m/s²"
                  />
                )}
                {settings.brake_pedal_normalized.display && (
                  <MetadataRow
                    property="brake_pedal_normalized"
                    value={parseFloat(
                      (vehicleMotion.brake_pedal_normalized || 0).toFixed(3)
                    )}
                  />
                )}
                {settings.gear.display && (
                  <MetadataRow property="gear" value={vehicleMotion.gear} />
                )}
                {gnss && (
                  <>
                    {settings.heading.display && (
                      <MetadataRow
                        property="heading"
                        value={parseFloat((gnss.heading || 0).toFixed(6))}
                        unit="deg"
                      />
                    )}
                    {settings.heading_error.display && (
                      <MetadataRow
                        property="heading_error"
                        value={parseFloat((gnss.heading_error || 0).toFixed(6))}
                        unit="deg"
                      />
                    )}
                    {settings.hp_loc_altitude.display && (
                      <MetadataRow
                        property="hp_loc_altitude"
                        value={parseFloat(
                          (gnss.hp_loc_altitude || 0).toFixed(6)
                        )}
                      />
                    )}
                    {settings.hp_loc_latitude.display && (
                      <MetadataRow
                        property="hp_loc_latitude"
                        value={parseFloat(
                          (gnss.hp_loc_latitude || 0).toFixed(6)
                        )}
                      />
                    )}
                    {settings.hp_loc_longitude.display && (
                      <MetadataRow
                        property="hp_loc_longitude"
                        value={parseFloat(
                          (gnss.hp_loc_longitude || 0).toFixed(6)
                        )}
                      />
                    )}
                    {settings.position_covariance.display && (
                      <MetadataRow
                        property="position_covariance"
                        value={`[${convertPositionCovariance(
                          gnss.position_covariance
                        ).join(', ')}]`}
                        unit="cm²"
                      />
                    )}
                  </>
                )}
                {settings.speed.display && (
                  <MetadataRow
                    property="speed"
                    value={parseFloat((vehicleMotion.speed || 0).toFixed(3))}
                    unit="km/h"
                  />
                )}
                {settings.steering_angle.display && (
                  <MetadataRow
                    property="steering_angle"
                    value={parseFloat(
                      (vehicleMotion.steering_angle || 0).toFixed(3)
                    )}
                    unit="deg"
                  />
                )}
                {settings.steering_angle_normalized.display && (
                  <MetadataRow
                    property="steering_angle_normalized"
                    value={parseFloat(
                      (vehicleMotion.steering_angle_normalized || 0).toFixed(3)
                    )}
                  />
                )}
                {vehicleState && settings.turn_signal.display && (
                  <MetadataRow
                    property="turn_signal"
                    value={TurnSignal[vehicleState.turn_signal]}
                  />
                )}
              </>
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default MetadataLog
